import { Injectable } from '@angular/core';
import { MonModalService } from '@client/app/modules/modals/services/mon-modal/mon-modal.service';
import { TranslateService } from '@client/app/services/translate/translate.service';
import { MonEventService } from '@monsido/services/mon-event/mon-event.service';
import { MonUIRouterTransitionService } from '@monsido/services/ui-router-transition-service/ui-router-transition-service';
import { Domain } from '@monsido/modules/models/api/domain';
import { BehaviorSubject } from 'rxjs';
import { cloneDeep } from 'lodash';
import { JSONValue } from '@monsido/modules/report-center/components/dashboard/types';
import { StateService } from '@uirouter/core';

export type DomainScriptGuideSettings = {
    page_assist: {
        enabled?: boolean,
        direction?: string | null,
        manual_startup?: boolean,
        skip_to?: boolean,
        theme?: string | null
    },
    page_assist_v2: {
        accessibilityStatementURL?: string,
        alwaysOnTop?: boolean,
        buttonHoverColor?: string,
        buttonHoverDarkColor?: string,
        canSavePaSetting?: boolean,
        coordinates?: string,
        customInformationTextEnabled?: boolean,
        customInformationText?: string,
        direction?: string,
        enabled?: boolean,
        greeting?: string,
        icon_picture_url?: string,
        icon_shape?: string,
        included_languages?: string[],
        language?: string,
        linkColor?: string,
        linkColorDark?: string,
        logo_picture_base64?: string,
        logo_picture_url?: string,
        mainButtonBorderColor?: string,
        mainButtonBorderColorEnabled?: boolean,
        mainColor?: string,
        mainDarkColor?: string,
        main_color?: string | null,
        pixelsOnBottom?: string,
        pixelsOnLeft?: string,
        pixelsOnRight?: string,
        pixelsOnTop?: string,
        skip_to?: boolean,
        switchAccessibilityStatementURL?: boolean,
        textColor?: string,
        textDarkColor?: string,
        text_color?: string,
        theme?: string,
        title?: string,
        title_text?: string,
    },
    heatmap: {
        enabled?: boolean
    },
    page_correct: {
        admin_only?: boolean,
        enabled?: boolean,
        enabled_checks?: string[]
    },
    statistics: {
        cookieless_tracking?: boolean,
        enabled?: boolean,
        document_tracking?: {
            enabled: boolean,
            document_cls: string | null,
            document_ext: string[],
            document_ignore_cls: string | null,
        }
    },
    cookie_banner: {
        theme?: {
            buttonColor: string,
            buttonTextColor: string
        },
        enabled?: boolean,
        direction?: string,
        optOutUrl?: string,
        hasOverlay?: boolean,
        icon_shape?: string,
        defaultLanguage?: string,
        cookies_overview?: string[],
        icon_picture_url?: string,
        cookie_policy_url?: string,
        firstTimeEnableCM?: boolean,
        privacyRegulation?: string,
        included_languages?: string[],
        per_category_consent?: boolean,
        explicit_reject_option?: boolean,
        enable_iab_and_consent_fw?: boolean,
        support_google_consent_mode?: boolean
    }
};

export type DomainScriptGuideOptions = {
    statistics: {
        value: JSONValue,
        cookieLessTracking: JSONValue,
        documentTracking: JSONValue,
        documentClass: JSONValue,
        documentIgnoreClass: JSONValue,
        filledDocumentExtensions: JSONValue,
    },
    heatmaps: {
        value: JSONValue
    },
    pageAssist: {
        accessToDomain: JSONValue
    },
    pageFix: {
        value: JSONValue
    }
};

@Injectable({
    providedIn: 'root',
})
export class FormDomainScriptGuideService {
    domain: Domain | null = null;
    editedScriptBlock = false;
    scriptSettings: DomainScriptGuideSettings = {
        page_assist: {},
        statistics: {},
        cookie_banner: {},
        heatmap: {},
        page_assist_v2: {},
        page_correct: {},
    };
    isPageAssistEdited = false;
    showScriptObs = new BehaviorSubject(true);

    constructor (
        private monModalService: MonModalService,
        private translateService: TranslateService,
        private monEventService: MonEventService,
        private monUIRouterTransitionService: MonUIRouterTransitionService,
        private state: StateService,
    ) {
        this.monEventService.addListener('change-editedScriptBlock-status', () => {
            this.editedScriptBlock = false;
        });
    }
    setShowScript (value: boolean): void {
        this.showScriptObs.next(value);
    }

    setDomain (domain: Domain): void {
        this.domain = domain;
        this.editedScriptBlock = false;
        this.isPageAssistEdited = false;
    }

    setPageAssistEdit (pageAssistModel): void {
        if (pageAssistModel && pageAssistModel.legacySettings) {
            this.scriptSettings.page_assist = pageAssistModel.legacySettings;
        }
        if (pageAssistModel && pageAssistModel.settings) {
            this.scriptSettings.page_assist_v2 = pageAssistModel.settings;
        }
    }

    setCookieBannerEdit (cookieBannerModel): void {
        this.scriptSettings.cookie_banner = cookieBannerModel.settings;
    }

    getDomain (): Domain | null {
        return cloneDeep(this.domain);
    }

    isScriptBlockEdited (): boolean {
        return this.editedScriptBlock || this.isPageAssistEdited;
    }

    setScriptSettingBlock (key: string, setting: JSONValue, manualEdit: boolean): void {
        if (manualEdit) {
            this.editedScriptBlock = true;
        }
        this.scriptSettings[key] = setting;
    }

    getDomainSettings (): DomainScriptGuideSettings {
        return this.scriptSettings;
    }

    getParameters (domain: Domain, pageAssistModel, options: DomainScriptGuideOptions): string {
        let parameters = '';
        options = options || {};
        const statisticsOptions = options.statistics || {};
        const heatmapsOptions = options.heatmaps || {};
        const pageFixOptions = options.pageFix || {};
        const pageAssistOptions = options.pageAssist || {};
        parameters += this.tab(2) + 'token: "' + domain.token + '",\n';
        // STATISTICS START
        if (statisticsOptions.value) {
            parameters += this.tab(2) + 'statistics: {\n';
            parameters += this.tab(3) + 'enabled: true,\n';
            parameters += this.tab(3) + 'cookieLessTracking: ' + statisticsOptions.cookieLessTracking + ',\n';
            parameters += this.tab(3) + 'documentTracking: {\n';
            parameters += this.tab(4) + 'enabled: ' + statisticsOptions.documentTracking + ',\n';
            parameters += this.tab(4) + 'documentCls: "' + statisticsOptions.documentClass + '",\n';
            parameters += this.tab(4) + 'documentIgnoreCls: "' + statisticsOptions.documentIgnoreClass + '",\n';
            parameters += this.tab(4) + 'documentExt: ' + this.stringify(statisticsOptions.filledDocumentExtensions) + ',\n';
            parameters += this.tab(3) + '},\n';
            parameters += this.tab(2) + '},\n';
        }
        // STATISTICS END

        // HEATMAP START
        if (heatmapsOptions.value) {
            parameters += this.tab(2) + 'heatmap: {\n';
            parameters += this.tab(3) + 'enabled: true,\n';
            parameters += this.tab(2) + '},\n';
        }
        // HEATMAP END

        // PAGECORRECT START
        if (pageFixOptions.value) {
            parameters += this.tab(2) + 'pageCorrect: {\n';
            parameters += this.tab(3) + 'enabled: true,\n';
            parameters += this.tab(2) + '},\n';
        }
        // PAGECORRECT END

        // PAGEASSIST START
        if (pageAssistOptions.accessToDomain && pageAssistModel.getVersionState() === 'v2' && pageAssistModel.getIsEnabled()) {
            parameters += this.tab(2) + 'pageAssistV2: {\n';
            parameters += this.tab(3) + 'enabled: true,\n';
            parameters += this.tab(3) + 'theme: "' + pageAssistModel.settings.theme + '",\n';
            parameters += this.tab(3) + 'mainColor: "' + pageAssistModel.settings.mainColor + '",\n';
            parameters += this.tab(3) + 'textColor: "' + pageAssistModel.settings.textColor + '",\n';
            parameters += this.tab(3) + 'linkColor: "' + pageAssistModel.settings.linkColor + '",\n';
            parameters += this.tab(3) + 'buttonHoverColor: "' + pageAssistModel.settings.buttonHoverColor + '",\n';
            parameters += this.tab(3) + 'mainDarkColor: "' + pageAssistModel.settings.mainDarkColor + '",\n';
            parameters += this.tab(3) + 'textDarkColor: "' + pageAssistModel.settings.textDarkColor + '",\n';
            parameters += this.tab(3) + 'linkColorDark: "' + pageAssistModel.settings.linkColorDark + '",\n';
            parameters += this.tab(3) + 'buttonHoverDarkColor: "' + pageAssistModel.settings.buttonHoverDarkColor + '",\n';
            if (pageAssistModel.settings.mainButtonBorderColorEnabled) {
                parameters += this.tab(3) + 'mainButtonBorderColor: "' + pageAssistModel.settings.mainButtonBorderColor + '",\n';
            }
            parameters += this.tab(3) + 'greeting: "' + (pageAssistModel.settings.greeting || '') + '",\n';
            parameters += this.tab(3) + 'direction: "' + pageAssistModel.settings.direction + '",\n';
            parameters +=
                this.tab(3) +
                'coordinates: "' +
                pageAssistModel.settings.pixelsOnTop +
                ' ' +
                pageAssistModel.settings.pixelsOnRight +
                ' ' +
                pageAssistModel.settings.pixelsOnBottom +
                ' ' +
                pageAssistModel.settings.pixelsOnLeft +
                '",\n';
            parameters += this.tab(3) + 'iconShape: "' + pageAssistModel.settings.icon_shape + '",\n';
            parameters += this.tab(3) + 'title: "' + pageAssistModel.settings.title + '",\n';
            parameters += this.tab(3) + 'titleText: "' + pageAssistModel.settings.title_text + '",\n';
            parameters += this.tab(3) + 'iconPictureUrl: "' + pageAssistModel.settings.icon_picture_url + '",\n';
            parameters += this.tab(3) + 'logoPictureUrl: "' + pageAssistModel.settings.logo_picture_url + '",\n';
            parameters += this.tab(3) + 'logoPictureBase64: "' + pageAssistModel.settings.logo_picture_base64 + '",\n';
            parameters += this.tab(3) + 'languages: ["' + pageAssistModel.settings.included_languages.join('", "') + '"],\n';
            parameters += this.tab(3) + 'defaultLanguage: "' + (pageAssistModel.settings.language || '') + '",\n';
            parameters += this.tab(3) + 'skipTo: ' + pageAssistModel.settings.skip_to + ',\n';
            if (pageAssistModel.settings.switchAccessibilityStatementURL) {
                parameters +=
                    this.tab(3) + 'accessibilityStatementURL: "' + (pageAssistModel.settings.accessibilityStatementURL || '') + '",\n';
            }
            if (pageAssistModel.settings.customInformationTextEnabled) {
                parameters += this.tab(3) + 'customInformationText: "' + (pageAssistModel.settings.customInformationText || '') + '",\n';
            }
            parameters += this.tab(3) + 'alwaysOnTop: ' + pageAssistModel.settings.alwaysOnTop + ',\n';
            parameters += this.tab(2) + '},\n';
        }

        if (pageAssistOptions.accessToDomain && pageAssistModel.getVersionState() === 'v1' && pageAssistModel.getIsEnabled()) {
            parameters += this.tab(2) + 'pageAssist: {\n';
            parameters += this.tab(3) + 'enabled: true,\n';
            parameters += this.tab(3) + 'theme: "' + pageAssistModel.legacySettings.theme + '",\n';
            parameters += this.tab(3) + 'direction: "' + pageAssistModel.legacySettings.direction + '",\n';
            parameters += this.tab(3) + 'skipTo: ' + pageAssistModel.legacySettings.skip_to + ',\n';
            parameters += this.tab(2) + '},\n';
        }
        // PAGEASSIST END
        return parameters;
    }

    getCookieBannerParameters (domain: Domain, cookieBannerModel, options): string {
        let parameters = '';
        const cmSettings = cookieBannerModel.settings;
        options = options || {};
        const cookieBannerOptions = options.cookieBanner || {};
        parameters += this.tab(2) + 'token: "' + domain.token + '",\n';

        if (cmSettings) {
            if (cookieBannerOptions.accessToDomain) {
                parameters += this.tab(2) + 'privacyRegulation: ' + JSON.stringify(cmSettings.privacyRegulation) + ',\n';
                // settings
                parameters += this.tab(2) + 'settings: {\n';
                parameters += this.tab(3) + 'manualStartup: false,\n';
                parameters += this.tab(3) + 'hideOnAccepted: false,\n';
                parameters += this.tab(3) + 'perCategoryConsent: ' + cmSettings.per_category_consent + ',\n';
                parameters += this.tab(3) + 'explicitRejectOption: ' + cmSettings.explicit_reject_option + ',\n';
                parameters += this.tab(3) + 'hasOverlay: ' + cmSettings.hasOverlay + ',\n';
                parameters += this.tab(2) + '},\n';
                // i18n
                parameters += this.tab(2) + 'i18n: {\n';
                parameters += this.tab(3) + 'languages: ["' + cmSettings.included_languages.join('", "') + '"], \n';
                parameters += this.tab(3) + 'defaultLanguage: "' + cmSettings.defaultLanguage + '"\n';
                parameters += this.tab(2) + '},\n';
                // theme
                parameters += this.tab(2) + 'theme: {\n';
                parameters += this.tab(3) + 'buttonColor: "' + cmSettings.theme.buttonColor + '",\n';
                parameters += this.tab(3) + 'buttonTextColor: "' + cmSettings.theme.buttonTextColor + '",\n';
                parameters += this.tab(3) + 'iconPictureUrl: "' + cmSettings.icon_picture_url + '",\n';
                parameters += this.tab(3) + 'iconShape: "' + cmSettings.icon_shape + '",\n';
                parameters += this.tab(3) + 'position: "' + cmSettings.direction + '",\n';
                parameters += this.tab(2) + '},\n';
                // links
                parameters += this.tab(2) + 'links: {\n';
                parameters += this.tab(3) + 'cookiePolicyUrl: "' + (cmSettings.cookie_policy_url || '') + '",\n';
                if (cmSettings.cookieOverviewLinkEnabled) {
                    parameters += this.tab(3) + 'linkToCookieOverview: "' + (cmSettings.linkToCookieOverview || '') + '",\n';
                }

                parameters += this.tab(3) + 'optOutUrl: "' + cmSettings.optOutUrl + '",\n';
                parameters += this.tab(2) + '},\n';
            }
            parameters += this.tab(1) + '};\n';
            if (cmSettings.add_empty_callback) {
                parameters += '\n';
                parameters += this.tab(1) + 'window.MonsidoCookieOnLoad = function() {};\n';
                parameters += this.tab(1) + 'window.MonsidoCookieOnShow = function() {};\n';
                parameters += this.tab(1) + 'window.MonsidoCookieOnHide = function() {};\n';
                parameters += this.tab(1) + 'window.MonsidoCookieOnAccept = function() {};\n';
            }

            if (cmSettings.add_empty_event_listeners) {
                parameters += '\n';
                parameters += this.tab(1) + 'window.addEventListener(\'MonsidoCookieOnLoad\', function() {});\n';
                parameters += this.tab(1) + 'window.addEventListener(\'MonsidoCookieOnShow\', function() {});\n';
                parameters += this.tab(1) + 'window.addEventListener(\'MonsidoCookieOnHide\', function() {});\n';
                parameters += this.tab(1) + 'window.addEventListener(\'MonsidoCookieOnAccept\', function() {});\n';
            }
        }

        return parameters;
    }

    stringify (value: unknown): string {
        return JSON.stringify(value);
    }

    tab (times = 1): string {
        let result = '';
        for (let i = 0; i < times; i++) {
            result = result + '&nbsp;&nbsp;&nbsp;&nbsp;';
        }
        return result;
    }

    confirmOpenCookieOverviewPagePopup (domainId: number, PageHref: string, isLiteUser: boolean): void {
        const text = isLiteUser ? this.translateService.getString('Consent Manager') : this.translateService.getString('Data Privacy');
        this.monModalService.customDialog({
            title: this.translateService.getString('Finish the setup by adding your cookies'),
            message:
this.translateService.getString('To finish setting up your manual Cookie Overview go to the selected domain’s ') + text + '.',
            buttons: [
                {
                    label: this.translateService.getString('I’ll do it later'),
                    className: 'btn btn-secondary',
                    value: 'openDataPrivacy',
                    callback: (): void => {},
                },
                {
                    label: text,
                    className: 'btn btn-primary',
                    value: 'close',
                    callback: (): void => {
                        return this.monUIRouterTransitionService.onTransitionFinished(() => {
                            this.state.go(PageHref, {
                                domainId: domainId,
                            });
                        });
                    },
                },
            ],
        }, {});

    }
}
